import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01574a7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["placeholder", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (!_ctx.textArea)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          class: _normalizeClass([{ 'no-border-radius': !_ctx.borderRadius }, "input"]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          placeholder: _ctx.placeholder,
          onInput: _cache[1] || (_cache[1] = () => _ctx.$emit('update:modelValue', _ctx.inputValue)),
          onChange: _cache[2] || (_cache[2] = () => _ctx.$emit('change')),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('onEnter')), ["enter"])),
          type: _ctx.type
        }, null, 42, _hoisted_2)), [
          [_vModelDynamic, _ctx.inputValue]
        ])
      : _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 2,
          class: "textarea",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputValue) = $event)),
          onInput: _cache[5] || (_cache[5] = () => _ctx.$emit('update:modelValue', _ctx.inputValue))
        }, null, 544)), [
          [_vModelText, _ctx.inputValue]
        ])
  ]))
}